
import { Component, Vue, Inject, Prop } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { PROVIDER, SERVICES as S } from "@/config/literals";

import UpdateUi from "@/components/Core/Update/UpdateUI.vue";

import { HydraulicSystemGroupInfos } from "@/domain";

import { IHydraulicSystemGroupService } from "@/services";

@Component({
  components: {
    UpdateUi,
  },
})
export default class GroupUpdate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  item!: HydraulicSystemGroupInfos;

  @Prop({ required: true })
  forbiddenNameMonograms!: string[];

  get hydraulicSystemGroupService() {
    return this.container.resolve<IHydraulicSystemGroupService>(
      S.HYDRAULIC_SYSTEM_GROUP_SERVICE
    );
  }

  loading: boolean = false;
  name: string | null = null;
  monogram: string | null = null;
  nameTrigramRule: string | boolean = true;

  mounted() {
    this.name = this.item.name;
    this.monogram = this.item.monogram;
  }

  updateName(name: string) {
    this.name = name;
    this.triggerValidation();
  }

  updateMonogram(monogram: string) {
    this.monogram = monogram;
    this.triggerValidation();
  }

  async update() {
    try {
      this.loading = true;
      const groupUpdated = await this.hydraulicSystemGroupService.update(
        this.item.id,
        {
          name: this.name!,
          monogram: this.monogram!,
          disabled: this.item.disabled,
        }
      );
      this.$emit("update", groupUpdated);
    } finally {
      this.loading = false;
    }
  }

  triggerValidation() {
    if (this.monogram == null || this.name == null) {
      this.nameTrigramRule = true;
    } else {
      const filtered = _.filter(
        this.forbiddenNameMonograms,
        (s) =>
          s !=
          this.item.name.toUpperCase() + "-" + this.item.monogram.toUpperCase()
      );
      this.nameTrigramRule =
        _.findIndex(
          filtered,
          (s) =>
            s == this.name!.toUpperCase() + "-" + this.monogram!.toUpperCase()
        ) == -1
          ? true
          : "Le couple Nom - Monogramme est déjà existant";
    }
  }
}
